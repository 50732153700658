@use '../../Assets/css/var';

.participate-info{
    p{
        font-size: 18px;
    }
  }
  .rm_section {
    padding: 50px 0;
  }
  .rm_main {
    margin-bottom: 20px;
    margin-top: 30px;
  }
  .rm_title {
    position: relative;
    z-index: 1;
    span {
      display: inline-block;
      color: #ff78f5;
      font-size: 16px;
      font-weight: 400;
      text-transform: uppercase;
      font-family: "Russo One", sans-serif;
      margin-bottom: 15px;
    }
    .img {
      margin-left: 10px;
      margin-right: 10px;
      margin-top: -1px;
    }
    h2 {
      color: rgb(255, 255, 255);
      text-transform: uppercase;
      margin-bottom: 10px;
    }
  }
  .rm-item {
    display: flex ;
    background: linear-gradient(to right, rgb(98 74 153 / 24%), #000000);
    border: 1px solid rgba(78, 42, 42, 0.5058823529);
    width: 100%;
    max-width: 500px;
    padding: 15px;
    height: 180px;
    // justify-content: center;
    margin: 6px auto;
    transition: transform 0.4s ease-in-out;
}
  
  .rm-item:hover {
    box-shadow: 0px 6px 30px #ff0b0b7e;
 
}
  .number-image {
    margin-right: 12px;
    flex: 0 0 21%;
    img {
      filter: hue-rotate(87deg);
    }
  }
  .description {
    color: #b4b4b9;
  }
  .section_rm-arrow-wrapper {
    align-items: center;
    background-image: linear-gradient(90deg,#e50e2a,#bf031c);
    bottom: 7px;
    display: flex;
    height: 2rem;
    justify-content: center;
    left: auto;
    overflow: hidden;
    position: absolute;
    right: 0px;
    top: auto;
    width: 2rem;
    bottom: 0;
}
.section_rm-arrow {
    height: 1.25rem;
    position: absolute;
    // left: 0px;
    svg{
        width: 20px;
        height: 20px;
    }
}


// @media only screen and (min-width: 543px) and (max-width: 768px) {
//   .section_rm-arrow-wrapper {
//     display: none;
//   }
// }

