.adv-heading {
  font-weight: 600;
  font-size: 45px;
  font-family: "techmont-bold", sans-serif;
}

.adv-item {
  background-image: linear-gradient(to right, rgba(229, 14, 42, 0), #e50e2a);
  width: 100%;
  height: 300px;
  display: flex;
  flex: 1;
  padding: 1px;
}

.adv-info {
  background-color: #111;
  background-image: linear-gradient(to right, rgba(33, 17, 70, 0.65), #000000);
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 297px;
  display: flex;
  position: relative;
}

.quote-bg-gradient {
  z-index: 5;
  filter: blur(30px);
  background-color: rgba(229, 14, 42, 0.2);
  border-radius: 100%;
  width: 42vw;
  height: 11vw;
  position: absolute;
}

.wallet-bottom {
  margin-top: -108px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 526px;
  height: 526px;
  pointer-events: none;
}

.wallet-bottom-img {
  margin-top: -50px;
  margin-right: -152.68px;
  width: 678.68px;
  height: 576px;
  -o-object-fit: contain;
     object-fit: contain;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  filter: saturate(120%);
}

.abt-img1::-webkit-media-controls {
  display: none !important;
}

.abt-img1::-webkit-media-controls-overlay-play-button {
  display: none !important;
}

@media only screen and (max-width: 767px) {
  .adv-heading {
    font-weight: 600;
    font-size: 32px;
    font-family: "techmont-bold", sans-serif;
  }
}
@media (max-width: 1375px) and (min-width: 1280px) {
  .adv-item {
    height: 310px;
  }
  .adv-info {
    height: 307px;
  }
}/*# sourceMappingURL=Advantages.css.map */