.tokenomics-section {
  padding: 50px 0px;
  position: relative;
}

.tokenomics-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: -130px;
  background-size: cover;
  background-position: center;
  opacity: 0.3;
  z-index: -1;
}

.tkncontainer {
  background: #7e0e0e;
  padding-top: 2px;
  padding-bottom: 2px;
  border-radius: 30px;
}

.tokenomics-wrapper {
  display: flex;
  border-radius: 30px;
  background-color: #00041a;
}

.tkntable {
  display: flex;
  justify-content: center;
}

.tokenimg {
  max-width: 530px;
}

.tknimg2 {
  mix-blend-mode: exclusion;
  height: auto;
  width: 100%;
}

.slider-bg-wrapper {
  z-index: 0;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 5%;
  right: auto;
}

.slider-bg-wrapper-img {
  z-index: 10;
  -o-object-fit: fill;
     object-fit: fill;
  max-width: none;
  height: 82%;
  position: absolute;
  top: -3.7rem;
  bottom: auto;
  left: -7.5rem;
  right: auto;
}

.slider-bg-gradient {
  z-index: 5;
  filter: blur(60px);
  background-color: rgba(229, 14, 42, 0.2);
  border-radius: 100%;
  width: 42vw;
  height: 21vw;
  position: absolute;
}

.token-metrics {
  border: 1px solid rgba(145, 122, 227, 0.26);
  background-color: #07011f;
  background-image: radial-gradient(circle at 0 100%, rgba(229, 14, 42, 0.22), rgba(229, 14, 42, 0) 54%);
  color: #ffffff;
  border-radius: 15px;
  padding: 30px 25px;
  max-width: 350px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  height: auto;
}

.token-metrics h2 {
  margin-top: 0;
  font-size: 20px;
}

.token-metrics ul {
  list-style-type: none;
  text-align: justify;
  padding-top: 15px;
}

.token-metrics ul li {
  margin: 15px 0px;
  display: flex;
  justify-content: space-between;
  font-size: 18px;
}

.token-metrics ul li strong {
  display: inline-block;
  width: 155px;
  color: #ad2b2b;
}

.token-metrics ul li img {
  vertical-align: middle;
  width: 25px;
  height: 25px;
  margin-right: 5px;
}

@media only screen and (max-width: 500px) {
  .token-metrics {
    padding: 25px 15px;
    margin: 0 5px;
  }
  .token-metrics ul li {
    font-size: 16px;
  }
}
@media only screen and (max-width: 400px) {
  .token-metrics {
    padding: 20px 10px;
    margin: 0 5px;
  }
  .token-metrics ul li {
    font-size: 16px;
  }
}/*# sourceMappingURL=Tokenomics.css.map */