.section.hero {
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 90vh;
  position: relative;
}

.hero-aling {
  justify-content: center;
  display: flex;
  position: relative;
}

.hero-heading {
  font-family: "techmont-bold", sans-serif;
  font-size: 60px;
  margin-bottom: 30px;
  text-align: center;
  text-shadow: 0 2px 10px rgba(206, 0, 0, 0.6352941176);
  word-break: break-word;
  font-weight: 700;
  line-height: 1;
  z-index: 1;
}
.hero-heading h1 {
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 0;
  margin-top: 0;
  color: #fff;
}

.h2div {
  border: 1px solid #504f4f;
  padding: 10px;
  border-radius: 20px;
  background-image: linear-gradient(to bottom, rgba(35, 22, 137, 0.349), #000);
}
.h2div h3 {
  font-family: "techmont-bold", sans-serif;
  color: #b80707;
}

.hero-div {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  max-width: 850px;
  text-align: center;
  position: relative;
}
.hero-div p {
  font-weight: 400;
  font-size: 18px;
  color: rgb(255, 255, 255);
  line-height: 1.5;
  margin-block: 0px;
  font-family: "Syne Mono", monospace;
  font-style: normal;
}
.hero-div h3 {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.2;
  margin-bottom: 0;
  margin-top: 0;
  color: #b80707 !important;
}

.hero-logo {
  height: auto;
  margin-bottom: 10px;
  margin-top: 25px;
  max-width: 200px;
  width: 150px;
}

.hero-desc {
  z-index: 1;
}

.hero-communities {
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  background-image: linear-gradient(90deg, #e50e2a, #bf031c);
  display: inline-block;
  text-shadow: none;
}

.hero-communities::-moz-selection {
  background: none;
}

.hero-communities::selection {
  background: none;
}

.secondary-heading {
  font-family: "techmont-bold", sans-serif;
  font-size: 22px;
  font-weight: 300;
  line-height: 1.1;
  margin-left: auto;
  margin-right: auto;
  max-width: 620px;
  text-shadow: 0 1px 10px hwb(0 7% 28%);
}

.h2div {
  z-index: 1;
}

.bnrbtn {
  gap: 20px;
  z-index: 1;
}
.bnrbtn .w-button {
  color: #fff;
  line-height: inherit;
  cursor: pointer;
  border: 0;
  border-radius: 0;
  text-decoration: none;
  display: inline-block;
}
.bnrbtn .button-red-shadow {
  letter-spacing: 3px;
  background-color: rgba(184, 7, 7, 0.3);
  border: 1.5px solid rgba(255, 61, 61, 0.4392156863);
  border-radius: 4px;
  margin-top: 5px;
  margin-left: 0;
  font-family: "techmont-bold", sans-serif;
  font-size: 16px;
  transition: background-color 0.3s;
  box-shadow: 0 0 100px #ff3d3d;
  transition: transform 0.3s ease-in-out;
}

.bnr-btc {
  width: 380px;
  height: 380px;
  position: absolute;
  bottom: -192px;
  left: -305px;
  z-index: 0;
  animation: moveUp 4s infinite ease-in-out;
}

.eth-img {
  width: 100%;
  height: 100%;
  z-index: 1;
  filter: saturate(120%);
}

.bnr-eth-bg {
  width: 160px;
  height: 160px;
  position: absolute;
  border-radius: 50%;
  background-color: #ff214a;
  filter: blur(90px);
  z-index: -1;
}

.bnr-eth {
  width: 380px;
  height: 380px;
  position: absolute;
  top: -101px;
  right: -305px;
  z-index: 0;
  animation: moveDown 4s infinite ease-in-out;
}

.ecosystem {
  background: #7e0e0e;
  padding-top: 2px;
  padding-bottom: 2px;
  border-radius: 30px;
}

.ecosystem-btn {
  border-radius: 30px;
  background-color: #00041a;
}

.buttonGroup .tech_btn {
  height: 55px;
  width: 160px;
}

.tech_btn {
  background-color: rgba(0, 0, 0, 0);
  border: none;
  border-radius: 10px;
  display: inline-block;
  height: 55px;
  outline: none;
  position: relative;
  width: 100%;
  max-width: 160px;
  transition: transform 0.3s ease-in-out;
}

.tech_btn:hover {
  transform: translateY(-6px);
}

.tech_btn i:not(.material-icons),
.tech_btn i:nth-child(2) {
  background: linear-gradient(45deg, #ff323e, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), #7710ff);
}

.tech_btn i:not(.material-icons):hover,
.tech_btn i:nth-child(2):hover {
  background: linear-gradient(45deg, #7710ff, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), #ff323e);
}

.tech_btn i:not(.material-icons) {
  border-radius: 10px;
  display: block;
  inset: -2px;
  position: absolute;
}

.tech_btn i:nth-child(2) {
  filter: blur(10px);
}

.tech_btn span {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6509803922);
  border-radius: 10px;
  color: #fff;
  display: flex;
  justify-content: center;
  left: 0;
  letter-spacing: 1.5px;
  overflow: hidden;
  text-transform: capitalize;
}

.tech_btn span::before {
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
}

.tech_btn span:before {
  content: "";
  left: -50%;
  transform: skew(25deg);
}

.tech_btn span,
.tech_btn span:before {
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
}

@keyframes moveUp {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-50px);
  }
}
@keyframes moveDown {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(50px);
  }
}
@media only screen and (max-width: 1240px) {
  .bnr-eth {
    right: -240px;
  }
  .bnr-btc {
    left: -240px;
  }
}
@media only screen and (max-width: 767px) {
  .hero-heading {
    font-size: 40px;
  }
  .bnr-btc {
    left: -130px;
  }
  .bnr-eth {
    top: -160px;
    right: -130px;
  }
}
@media only screen and (max-width: 575px) {
  .bnr-btc {
    left: -120px;
    opacity: 0.6;
  }
  .bnr-eth {
    opacity: 0.6;
  }
}
@media only screen and (max-width: 475px) {
  .tech_btn {
    max-width: 130px;
  }
}
@media only screen and (max-width: 375px) {
  .tech_btn {
    max-width: 110px;
  }
}/*# sourceMappingURL=Banner.css.map */