@use "../../Assets/css/var";

.accordion-button {
    padding: 20px;
    background-image: linear-gradient(to bottom, rgb(36 34 104 / 38%), #000);
    color: #ffffff;
    outline: none;
    box-shadow: none;
    -webkit-backdrop-filter: blur(15px);
    backdrop-filter: blur(15px);
    border-radius: 7px;
    border: 1px solid rgb(104 55 55 / 59%);

    &::after {
        filter: invert(1) brightness(3);
    }
}

.slide-box {
    padding: 20px;
    display: inline-block;
    width: 550px;
    text-decoration: none;
    position: relative;
    z-index: 2;
    height: 250px;
    transition: transform 0.5s ease-in-out;
}
.accordion-button svg {
    transition: transform 0.3s ease;
    flex-shrink: 0;
}

.accordion-button[aria-expanded="true"] svg {
    transform: rotate(180deg);
}
.accordion-button:hover {
    background-color: #301f1f;
}

.accordion-body {
    display: none;
    transition: max-height 0.5s ease;
    display: block;
    background-image: linear-gradient(90deg, rgb(131 84 219 / 21%), rgb(171 7 7 / 48%));
    border-radius: 0px 0px 5px 5px;
}

.faqOuterSection {
    position: relative;
    .accordion-item {
        background-color: transparent;
        border: none;
        margin-bottom: 15px;
        border-radius: 5px;
    }

    .treebox {
        padding: 0px;
        border-radius: 5px;
        text-align: left;
        background-color: transparent;
        border: none;
        color: #ffffff;
        height: 100%;
        cursor: pointer;
        position: relative;
        & > div {
            position: relative;
            z-index: 2;
        }

        b {
            position: relative;
            z-index: 2;
            font-weight: 400;
        }

        i {
            position: absolute;
            inset: -2px;
            display: block;
            border-radius: 5px;
        }

        i,
        i:nth-child(2) {
            background: linear-gradient(45deg, #64e9ff, #0e1538, #0e1538, #aa64ff);
        }

        i:nth-child(2) {
            filter: blur(10px);
        }

        span {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            text-transform: capitalize;
            color: #ffffff;
            letter-spacing: 1.5px;
            border-radius: 3px;
            background-color: rgba(14, 21, 56, 0.65);
            overflow: hidden;

            &::before {
                content: "";
                position: absolute;
                top: 0px;
                left: -50%;
                width: 100%;
                height: 100%;
                background-color: rgba(255, 255, 255, 0.075);
                transform: skew(25deg);
            }
        }
    }

    .accordion-body {
        padding: 15px;

        p {
            margin: 0px 0px 10px;
            color: #e2e2e2;

            a {
                color: #00d2ff;
            }
        }
    }

    .faqAssetsBox {
        text-align: center;

        position: relative;
        animation: moveUp 4s infinite ease-in-out;

        img {
            width: 100%;
            max-width: 450px;
            display: block;
            margin: 0 auto;
        }
    }
}
