
@use '../../Assets/css/var';
@tailwind base;
@tailwind components;
@tailwind utilities;



*,
*::before,
*::after {
    box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
    :root {
        scroll-behavior: smooth;
    }
}


@font-face {
    font-family: "techmont-bold";
    src: url("../fonts/Play-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
  }

html {
    scroll-behavior: smooth;
}

body {
    margin: 0;
    font-family: "Syne Mono", monospace;
    font-size: 16px;
    min-height: 100vh;
    font-weight: 500;
    line-height: 1.2;
    color: var.$colour-white;
    text-align: left;
    background-color: #00041a;
    word-break: break-word;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    &::before {
        content: "";
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        // background-image: url(../images/bgglass.png);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        z-index: -2;
        opacity: 0;
    }

    &::after {
        content: "";
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        // background-image: url(../images/backgif.gif);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        z-index: -3;
        transform: scale(1.1);
        filter: hue-rotate(30deg) brightness(0.5);
        opacity: 0.35;
    }
}




/* width */
::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background-color: var.$colour-theme-dark;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background-color: var.$colour-theme;
}


h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "techmont-bold", sans-serif;
    line-height: 1.1;
    color: #ffffff;
}
p{
    line-height: 1.5;
}




a {
    color: var.$colour-white;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
    cursor: pointer;

    &:hover {
        color: #e50e2a;
    }
}

.container {
    max-width: 1350px;
    width: calc(100% - 20px);
    margin-left: auto;
    margin-right: auto;
}




.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; 
  }
  .loader {
    width: var(--loader-size);
    height: var(--loader-size);
    border-radius: 50%;
    display: inline-block;
    position: relative;
    box-sizing: border-box;
    animation: rotation 1.5s linear infinite;
    img{
        max-width: 200px;
    }
  }
 





#my-scrollbar {
    width: 500px;
    height: 500px;
    overflow: auto;
}









// Media Query Css

@import 'media';