@use "../../Assets/css/var";

.about-heading {
    font-size: 45px;
    font-family: "techmont-bold", sans-serif;
    font-weight: 600;
}

.about-section {
    position: relative;
}
.abtbx {
    
    max-width: 550px;
}
.about-box {
    background-image: linear-gradient(to bottom, rgba(35, 22, 137, 0.288), #000);

    display: inline-block;
    width: 100%;
    text-decoration: none;
    position: relative;
    z-index: 2;
    height: auto;
    border: 1px solid rgba(78, 42, 42, 0.1725490196);
    transition: transform 0.5s ease-in-out;

    p {
        // padding-left: 10px;
        // padding-right: 10px;
        padding-bottom: 10px;
        color: #bababa;
        font-size: 18px;
        line-height: 1.5;
    }
    h3 {
        font-size: 30px;
    }
}
// .about-box:hover {
//     transform: scale(1.04);

// }
.padding-xsmall {
    padding: 0.5rem;
}
.section_about-arrow-wrapper {
    background-image: linear-gradient(90deg, #e50e2a, #bf031c);
    justify-content: center;
    align-items: center;
    width: 2.3rem;
    height: 2.1rem;
    display: flex;
    position: absolute;
    top: auto;
    bottom: 0%;
    left: auto;
    right: 0%;
    overflow: hidden;
}
.section_about-arrow {
    height: 1.25rem;
    position: absolute;
    left: 12px;
}
.section_about-arrow .invisble {
    position: absolute;
    left: -100%;
}
.w-embed:before,
.w-embed:after {
    content: " ";
    grid-area: 1/1/2/2;
    display: table;
}
.section_about-bg {
    z-index: -1;
    justify-content: center;
    align-items: center;
    padding-top: 16.08rem;
    display: flex;
    position: absolute;
    top: 0%;
    bottom: 0%;
    left: 0%;
    right: 0%;
    padding-bottom: 80px;
}
.section_about-bg-gradient {
    z-index: 5;
    filter: blur(30px);
    background-color: rgba(229, 14, 42, 0.32);
    border-radius: 100%;
    width: 42vw;
    max-width: 38.75rem;
    height: 21vw;
    max-height: 18.38rem;
    position: absolute;
}
.abt-img {
    max-width: 450px;
    height: auto;
    mix-blend-mode: exclusion;
}
.abt-img1 {
    // object-fit: cover;
    width: 100%;
}


@media only screen and (max-width: 992px) {

    .abt-img {
        max-width: 350px;
   
    }
}
@media only screen and (max-width: 767px) {
    .about-heading {
        font-weight: 600;
        font-size: 32px;

        font-family: "techmont-bold", sans-serif;
    }

    .abt-img {
        max-width: 310px;
   
    }
}



