@use "../../Assets/css/var";

header {
    position: relative;
    // filter: drop-shadow(0px -60px 60px #000b2b);
    top: 0px;
    z-index: 4;

    transition: all 0.3s ease-in-out;
    background: #00041a;
    -webkit-backdrop-filter: blur(15px);
    backdrop-filter: blur(15px);

    &.openmenu {
        position: sticky;
    }

    // .container {
    //     max-width: calc(100% - 330px);
    // }
}

.logo {
    img {
        width: 100%;
        max-width: 70px;
        padding: 5px 5px 5px 0px;
    }
}

.logoDesktop {
    top: 80px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);

    img {
        width: 100%;
        max-width: 120px;
        padding: 5px;
        transform: scale(1.5);
    }

    h2 {
        font-family: "defiworld-Bold1";
        font-size: 42px;
        color: #3df9ff;
    }

    ul {
        display: flex;
        font-family: "techmont-bold", sans-serif;
        margin: 0px;
        padding: 0px;
        justify-content: center;
        margin-bottom: -10px;
        text-shadow: 2px 2px 50px #50dbff;
    }

    ul li {
        list-style: none;
        letter-spacing: 6px;
        font-size: 32px;
        animation: ani 1.2s linear infinite;
    }

    ul li:nth-child(1) {
        animation-delay: 0.2s;
    }

    ul li:nth-child(2) {
        animation-delay: 0.6s;
    }

    ul li:nth-child(3) {
        animation-delay: 0.8s;
    }

    ul li:nth-child(4) {
        animation-delay: 1s;
    }

    ul li:nth-child(5) {
        animation-delay: 1.4s;
    }

    ul li:nth-child(6) {
        animation-delay: 1.8s;
    }

    ul li:nth-child(7) {
        animation-delay: 1.12s;
    }

    ul li:nth-child(8) {
        animation-delay: 1.16s;
    }

    ul li:nth-child(9) {
        animation-delay: 1.2s;
    }

    @keyframes ani {
        0% {
            text-shadow: 2px 2px 10px #ff5050;
        }

        50% {
            text-shadow: 2px 2px 20px #ff5050;
        }

        100% {
            text-shadow:
                0 0 7px #ff3d3d,
                0 0 20px #ff3d3d;
        }
    }
}

.menu {
    position: relative;
    top: 20px;

    li {
        display: inline-block;
        margin: 0px 12px;
    }

    a {
        display: block;
        text-decoration: none;
        text-transform: capitalize;
        font-size: 18px;
        letter-spacing: 1.5px;
        font-family: "techmont-bold", sans-serif;
        font-weight: 400;
        cursor: pointer;
        margin-bottom: 30px;
        text-shadow: 2px 2px 50px #ff5050;
    }
}

.balancebox {
    display: flex;
    align-items: center;

    li {
        display: inline-block;
        margin-top: 20px;

        &:nth-last-child(1) {
            margin-left: 10px;
        }

        span {
            padding: 10px 20px;
            border-radius: 4px;
            cursor: pointer;
            color: var.$colour-white;
            border: 1px solid var.$colour-theme;
            font-weight: 600;
            letter-spacing: 2px;
            position: relative;
            min-width: auto;
            margin: 0px;

            &::before {
                width: 100px !important;
            }

            &::after {
                width: 100px !important;
            }
        }

        strong {
            margin-left: 8px;
        }
    }
}

.rightboxmenu {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    bottom: 0px;
}

.hamburger {
    cursor: pointer;
    width: 35px !important;
    height: 26px;
    position: relative;
    margin-left: 15px;
    z-index: 1;

    span {
        cursor: pointer;
        border-radius: 1px;
        height: 3px;
        background-color: #ff02027d;
        display: inline-block;
        content: "";
        transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
        position: absolute;
        top: 10px;
        left: 0px;
        width: 100%;

        &:before {
            cursor: pointer;
            border-radius: 1px;
            height: 3px;
            width: 100%;
            background-color: var.$colour-theme;
            position: absolute;
            display: inline-block;
            content: "";
            top: -10px;
            left: 0px;
            transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
        }

        &:after {
            cursor: pointer;
            border-radius: 1px;
            height: 3px;
            width: 100%;
            background-color: var.$colour-theme;
            position: absolute;
            display: inline-block;
            content: "";
            bottom: -10px;
            left: 0px;
            transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
        }
    }
}

.hamburger.is-active {
    span {
        background-color: transparent;

        &:before {
            top: 0;
            transform: rotate(45deg);
        }

        &:after {
            top: 0;
            transform: translateY(-10px) rotate(-45deg);
            top: 10px;
        }
    }
}

.menubar {
    position: fixed;
    top: 0;
    left: -360px;
    width: 0px;
    height: 100%;
    z-index: 3;
    background-color: #041516;
    display: block !important;
    box-shadow: -20px 0px 30px 0px #000000;
    transition: all 0.3s ease-in-out;
  
    padding-left: 20px;
    margin-top: 60px;
    .menu {
        display: block;
        padding-top: 30px !important;

        li {
            display: block;
            margin-bottom: 10px;

            a {
                display: block;
                text-align: left;
                padding: 10px;
                font-size: 20px;
            }
        }
    }
}

.mobileMenu {
    left: 0;
    width: 100%;
}

.link {
    height: 30px;
    align-items: center;
    text-decoration: none;
    display: flex;
    color: #ffff;
}

.link:hover {
    color: #d31212;
}

.link:hover .link-container {
    transform: translateY(-20px);
}

.link:hover .link-title1 {
    transform: rotate(20deg);
}

.link:hover .link-title2 {
    transform: rotate(0);
}

.mask {
    position: relative;
    padding: 0;
    height: 20px;
    overflow: hidden;
}

.link-container {
    transition: transform 0.4s ease;
}

.title {
    display: block;
    line-height: 20px;
    transition: transform 0.4s ease;
    font-family: "techmont-bold", sans-serif;
}

.link-title1 {
    transform-origin: right center;
}

.link-title2 {
    transform-origin: left center;
    transform: rotate(20deg);
}

@media only screen and (max-width: 1840px) {
    // header .container {
    //     max-width: 950px;
    // }

    .menu li {
        margin: 0px 10px;
    }
}

@media only screen and (max-width: 1440px) {
    .menu li {
        margin: 0px 10px;
    }

    // header .container {
    //     max-width: 950px;
    // }

    header .before {
        width: 300px;
    }
}

@media only screen and (max-width: 992px) {
    .balancebox li span {
        padding: 10px 15px;
        letter-spacing: 1px;
        margin: 0px;
    }
    .menu a {
        font-size: 14px;
        letter-spacing: 0px;
        font-weight: 500;
    }
}

@media only screen and (max-width: 767px) {
    .rightboxmenu {
        bottom: 0px;
    }

    header::before {
        height: 120px;
    }

    .logoDesktop {
        top: 50px;
    }

    .logoDesktop ul li {
        font-size: 22px;
    }
}

@media only screen and (max-width: 992px) {
    .menu li {
        margin: 0px 9px;
    }

    // .balancebox li {
    //     font-size: 12px;
    // }
}

@media only screen and (max-width: 767px) {
    .logo img {
        max-width: 70px;
    }
}

@media only screen and (max-width: 390px) {
    .balancebox {
        font-size: 14px;
    }

    .balancebox li span {
        padding: 8px 10px;
    }

    .hamburger {
        width: 30px !important;
        height: 23px;
        margin-left: 10px;
    }
}
